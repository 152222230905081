import React, { useState } from "react";
import InviteUser from "../UserManagement/InviteUser";
import UserList from "../UserManagement/UserList";
import { Box, Button, Typography } from "@material-ui/core";

const headerHeight = 64;

const UserManagement = () => {
  const [selectedSection, setSelectedSection] = useState("invite");

  return (
    <Box
      sx={{
        display: "flex",
        height: "70vh",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: 3,
          borderRadius: 2,
          textAlign: "center",
          padding: 3,
          width: "100%",
          maxWidth: "800px",
        }}
      >
        {/* Navigation Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            marginBottom: 3,
          }}
        >
          <Button variant={selectedSection === "invite" ? "contained" : "outlined"} color="primary" onClick={() => setSelectedSection("invite")}>
            Invite User
          </Button>
          <Button variant={selectedSection === "users" ? "contained" : "outlined"} color="primary" onClick={() => setSelectedSection("users")}>
            Users
          </Button>
        </Box>

        {/* Main Content */}
        <Box sx={{ paddingTop: headerHeight, textAlign: "center" }}>{selectedSection === "invite" ? <InviteUser /> : <UserList />}</Box>
      </Box>
    </Box>
  );
};

export default UserManagement;
