import "./css/App.scss";
import { Switch, Route, BrowserRouter as Router, Redirect, withRouter } from "react-router-dom";
import Scroll from "./components/utils/ScrollToTop";
import Show from "./components/pages/Show";
import Shows from "./components/pages/Shows";
import Login from "./components/pages/Login";
import Product from "./components/pages/Product";
import Container from "@material-ui/core/Container";
import Header from "./components/layout/Header";
import SavingState from "./context/saving";
import AlertState from "./context/alert";
import ShowsState from "./context/shows";
import ShowState from "./context/show";
import AllImages from "./components/pages/AllImages";
import GitInfo from "react-git-info/macro";
import LogRocket from "logrocket";
import AuthState, { useAuthState } from "./context/auth";
import PrivateRoute from "./components/layout/PrivateRoute";
import SeasonReport from "./components/pages/SeasonReport";
import AutoCompletes from "./components/pages/AutoCompletes";
import UserManagement from "./components/pages/UserManagement/UserManagement";
if (process?.env.NODE_ENV === "production") {
  LogRocket.init("hcspzk/lily-buying");
}

function App() {
  return (
    <Router>
      <AlertState>
        <AuthState>
          <SavingState>
            <ShowsState>
              <ShowState>
                <Children />
              </ShowState>
            </ShowsState>
          </SavingState>
        </AuthState>
      </AlertState>
    </Router>
  );
}

export default App;

var Children = withRouter(function ({ location }) {
  const { isAuthenticated, userProfile } = useAuthState();
  return (
    <Container maxWidth="sm" style={{ paddingTop: 17, paddingBottom: 40 }}>
      <Header {...{ isLoggedIn: isAuthenticated }} />
      <Switch>
        <PrivateRoute exact path="/shows/:show_id" component={Show} />
        <PrivateRoute exact path="/shows" component={Shows} />
        <PrivateRoute exact path="/image-gallery" component={AllImages} />
        <PrivateRoute exact path="/season-report" component={SeasonReport} />
        <PrivateRoute exact path="/products/:product_id" component={Product} />
        <PrivateRoute exact path="/auto-completes" component={AutoCompletes} />
        {userProfile?.admin && <PrivateRoute exact path="/user-management" component={UserManagement} />}

        <Route exact path="/login" component={Login} />
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
      {/* <Scroll /> */}
    </Container>
  );
});

const gitInfo = GitInfo();
console.log(gitInfo);
