import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useSavingState } from "../../context/saving";
import { Link, useHistory } from "react-router-dom";
import { useScrollTrigger } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { useEffect } from "react";
import { useAuthState } from "../../context/auth";

const useStyles = makeStyles((theme) => ({
  root: {
   // zIndex: 1301,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  offset: theme.mixins.toolbar,
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  useEffect(() => {
    document.dispatchEvent(new CustomEvent(trigger ? "header-out" : "header-in"));
  }, [trigger]);

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function MenuAppBar({ isLoggedIn }) {
  const { savingState } = useSavingState();
  const { logout, userProfile } = useAuthState();
  const classes = useStyles();
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const accountOpen = Boolean(accountAnchorEl);
  const menuOpen = Boolean(menuAnchorEl);

  const handleAccountMenu = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };
  const handleMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const history = useHistory();

  if (!isLoggedIn) {
    return (
      <div>
        <AppBar position="fixed" classes={{ root: classes.root }}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Buying
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.offset} />
      </div>
    );
  }

  return (
    <div>
      <HideOnScroll>
        <AppBar position="fixed" classes={{ root: classes.root }}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleClose}
            >
              <Link to="/shows" className={classes.link}>
                <MenuItem onClick={handleClose}>All Shows</MenuItem>
              </Link>
              <Link to="/image-gallery" className={classes.link}>
                <MenuItem onClick={handleClose}>Gallery</MenuItem>
              </Link>
              <Link to="/season-report" className={classes.link}>
                <MenuItem onClick={handleClose}>Season Report</MenuItem>
              </Link>
              <Link to="/auto-completes" className={classes.link}>
                <MenuItem>Manage Autocompletes</MenuItem>
              </Link>
              {userProfile?.admin && (
                <Link to="/user-management" className={classes.link}>
                  <MenuItem onClick={handleClose}>User Management</MenuItem>
                </Link>
              )}
            </Menu>
            <Typography variant="h6" className={classes.title}>
              Buying
            </Typography>
            <Typography variant="subtitle2" color={savingState.startsWith("Error") ? "error" : "initial"} style={{ textAlign: "right" }}>
              {savingState}
            </Typography>

            <div>
              <IconButton onClick={handleAccountMenu} color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                id="account-appbar"
                anchorEl={accountAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={accountOpen}
                onClose={handleAccountClose}
              >
                <MenuItem
                  onClick={(e) => {
                    handleAccountClose(e);
                    logout();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <div className={classes.offset} />
    </div>
  );
}
