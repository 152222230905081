import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthState } from "../../../context/auth";
import { makeStyles } from "@material-ui/core/styles";

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "100%",
    overflow: "hidden", // Prevents any content from overflowing
  },
  tableContainer: {
    maxWidth: "100%", // Ensure it does not exceed screen width
    overflowX: "auto", // Enable scroll only if necessary
  },
  title: {
    fontWeight: "bold",
    color: "#3f51b5",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  table: {
    width: "100%",
    tableLayout: "fixed", // Prevent table from expanding
  },
  tableHead: {
    backgroundColor: "#3f51b5",
  },
  tableHeadCell: {
    color: "#fff",
    fontWeight: "bold",
    whiteSpace: "nowrap", // Prevents text wrapping inside cells
  },
  tableCell: {
    wordBreak: "break-word", // Ensures long text doesn't overflow
  },
  noDataText: {
    marginTop: theme.spacing(2),
  },
}));

const UserList = () => {
  const classes = useStyles();
  const { getAllUsers, deleteUser } = useAuthState();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getAllUsers();
      if (response?.success && response?.data?.length > 0) {
        setUsers(response.data);
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users. Please try again later.");
    }
    setLoading(false);
  };

  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await deleteUser(selectedUserId);
      if (response?.success) {
        setUsers((prevUsers) => prevUsers.filter((user) => user._id !== selectedUserId));
        toast.success("User deleted successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user. Please try again.");
    }
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  return (
    <Box className={classes.root}>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          style: {
            position: "absolute",
            top: "10%",
          },
        }}
      >
        {" "}
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this user?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h4" gutterBottom className={classes.title}>
        User List
      </Typography>

      {loading ? (
        <CircularProgress style={{ display: "block", margin: "30px auto" }} />
      ) : users.length > 0 ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell className={classes.tableHeadCell}>#</TableCell>
                <TableCell className={classes.tableHeadCell}>Email</TableCell>
                <TableCell className={classes.tableHeadCell}>Created At</TableCell>
                <TableCell className={classes.tableHeadCell}>Last Logout</TableCell>
                <TableCell className={classes.tableHeadCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user._id || index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell className={classes.tableCell}>{user.email}</TableCell>
                  <TableCell className={classes.tableCell}>{new Date(user.createdAt).toLocaleString()}</TableCell>
                  <TableCell className={classes.tableCell}>{user.lastLogOut ? new Date(user.lastLogOut).toLocaleString() : "N/A"}</TableCell>
                  <TableCell>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(user._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography align="center" color="textSecondary" className={classes.noDataText}>
          No users found.
        </Typography>
      )}
    </Box>
  );
};

export default UserList;
