import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Box, Typography, Paper, Grid, CircularProgress } from "@material-ui/core";
import { useAuthState } from "../../../context/auth";

const InviteUser = () => {
  const { inviteUser, getAllUsers } = useAuthState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await getAllUsers();
        console.log("Users:", users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    setEmail("");
    setPassword("");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setEmailError(false);
      setLoading(true);
      try {
        await inviteUser(email.trim(), password.trim());
        setMessage("User invited successfully.");
        setEmail("");
        setPassword("");
      } catch (error) {
        setMessage("Failed to invite user.");
      } finally {
        setLoading(false);
      }
    } else {
      setEmailError(true);
      setMessage("Invalid email address.");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: "30px", borderRadius: "10px", marginTop: "50px" }}>
        <Typography variant="h4" align="center" gutterBottom style={{ fontWeight: "bold", color: "#3f51b5" }}>
          Invite User
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={emailError ? "Invalid email address" : ""}
                autoComplete="off"
                InputProps={{ style: { backgroundColor: "#fff", borderRadius: "8px" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
                InputProps={{ style: { backgroundColor: "#fff", borderRadius: "8px" } }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" color="primary" type="submit" style={{ padding: "12px 32px", borderRadius: "8px", fontWeight: "bold" }} disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Send Invitation"}
              </Button>
            </Grid>
            {message && (
              <Grid item xs={12}>
                <Typography color={emailError ? "error" : "primary"} align="center">
                  {message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default InviteUser;
